<template>
  <v-container id="purchase" fluid tag="section" class="parent-element">
    <v-row class="sticky-child" v-if="this.id == null || this.id == undefined">
      <v-col class="d-flex" cols="4" sm="4">
        <v-select
          :items="searchItems"
          v-model="searchItem"
          label="Search By"
          hide-details="true"
          solo
        ></v-select>
      </v-col>
      <v-col cols="8" sm="8">
        <div class="search">
          <i class="fa fa-search"></i>
          <v-text-field
            :label="searchLabel + searchItem"
            :value="searchValue"
            :hint="searchError"
            v-model="searchValue"
            v-on:keyup.enter="findProduct"
            solo
          ></v-text-field>
          <button class="btn btn-primary" @click="findProduct">Search</button>
        </div>
      </v-col>
    </v-row>
    <v-alert color="red lighten-2" dark v-if="quantityError">
      {{ lowQuantity }}
    </v-alert>
    <v-card>
      <v-card-title> Products </v-card-title>
      <v-form ref="form" v-if="this.id == null || this.id == undefined">
        <v-container>
          <v-row>
            <v-col cols="6" md="6" v-for="(num, index) in purchaseNumber" :key="num">
              <v-card>
                <v-card-title>
                  {{ productDetails[index].name }}
                  <v-col class="text-right">
                    <v-icon
                      v-if="onlyView != true && editSalesOrder != true"
                      class="mt-6 ml-4"
                      @click="deletePurchase(index)"
                      :disabled="onlyView || editSalesOrder"
                      >mdi-delete</v-icon
                    >
                  </v-col>
                </v-card-title>
                <v-card-text class="ml-4">
                  <span style="width: 150px">
                    <b>{{
                      productDetails[index].product_variants.variant_quantity_per_pack +
                      "x " +
                      productDetails[index].product_variants.variant_volume_value +
                      " " +
                      productDetails[index].product_variants.variant_volume_type +
                      " " +
                      productDetails[index].product_variants.variant_type
                    }}</b>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                  <span style="width: 150px">
                    <b>UPC</b> :
                    {{
                      productDetails[index].product_variants.variant_upc_number
                    }}&nbsp;&nbsp;&nbsp;
                  </span>
                  <span style="width: 150px">
                    <b>SKU</b> :
                    {{
                      productDetails[index].product_variants.variant_sku_number
                    }}&nbsp;&nbsp;&nbsp;
                  </span>
                </v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        type="number"
                        min="1"
                        step="1"
                        class="purple-input"
                        label="Quantity"
                        v-model="quantity[index]"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        :disabled="onlyView"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-dialog v-model="deletePurchaseToggle" max-width="500px">
          <v-card>
            <v-card-title>Are you sure you want to delete Purchase?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deletePurchaseToggle = false"
                >Cancel</v-btn
              >
              <v-btn color="primary darken-1" small text @click="deletePurchaseItem"
                >Delete</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-form ref="form" v-if="this.editSalesOrder == true || this.onlyView == true">
        <v-container>
          <v-row>
            <v-col cols="6" md="6" v-for="(num, index) in purchaseNumber" :key="num">
              <v-card>
                <v-card-title>
                  <span v-if="getProduct(productId[index]).length > 0">{{
                    getProduct(productId[index])[0].name
                  }}</span>
                  <v-col class="text-right">
                    <v-icon
                      v-if="onlyView != true && editSalesOrder != true"
                      class="mt-6 ml-4"
                      @click="deletePurchase(index)"
                      :disabled="onlyView"
                      >mdi-delete</v-icon
                    >
                  </v-col>
                </v-card-title>
                <v-card-text class="ml-4">
                  <span style="width: 150px">
                    <b>{{
                      productDetails[index].product_variants[0]
                        .variant_quantity_per_pack +
                      "x " +
                      productDetails[index].product_variants[0].variant_volume_value +
                      " " +
                      productDetails[index].product_variants[0].variant_volume_type +
                      " " +
                      productDetails[index].product_variants[0].variant_type
                    }}</b>
                    &nbsp;&nbsp;&nbsp;
                  </span>

                  <span style="width: 150px">
                    <b>UPC</b> :
                    {{
                      productDetails[index].product_variants[0].variant_upc_number
                    }}&nbsp;&nbsp;&nbsp;
                  </span>
                  <span style="width: 150px">
                    <b>SKU</b> :
                    {{
                      productDetails[index].product_variants[0].variant_sku_number
                    }}&nbsp;&nbsp;&nbsp;
                  </span>
                </v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        type="number"
                        min="1"
                        step="1"
                        class="purple-input"
                        label="Quantity"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        v-model="quantity[index]"
                        :disabled="onlyView"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-dialog v-model="deletePurchaseToggle" max-width="500px">
          <v-card>
            <v-card-title>Are you sure you want to delete Purchase?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deletePurchaseToggle = false"
                >Cancel</v-btn
              >
              <v-btn color="primary darken-1" small text @click="deletePurchaseItem"
                >Delete</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-card>
        <v-card-title>
          <v-col> Sales Order Details </v-col>
          <v-col>
            <v-select
              :items="stores"
              label="Select Stores"
              item-text="name"
              item-value="_id"
              v-model="storeId"
              hide-details="true"
              :rules="selectRule"
              :disabled="onlyView"
            ></v-select> </v-col
        ></v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                v-model="sales_channel"
                :items="allSalesChannel"
                item-text="name"
                item-value="_id"
                label="Sales_Channel"
                :disabled="onlyView"
              ></v-select>
              <!-- <v-text-field
                  v-else
                  label="Damaged By(Name)"
                  :rules="inputRule"
                  :disabled="onlyView"
                ></v-text-field> -->
            </v-col>

            <v-col cols="3" md="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                :value="Date"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    :readonly="editSalesOrder"
                    :disabled="onlyView"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- <v-alert color="red lighten-2" dark v-if="showItemError">
        {{ showErrorItem }}
      </v-alert> -->
      <v-row>
        <v-col cols="12">
          <v-btn
            type="button"
            color="primary"
            class="mr-0 float-right"
            @click="submit"
            :loading="loading"
          >
            {{ onlyView == true ? "Back" : editSalesOrder == true ? "Update" : "Add" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      searchError: "",
      searchItems: ["UPC", "SKU"],
      searchLabel: "Searching By ",
      searchValue: null,
      searchItem: "UPC",
      productDetails: [],
      stores: [],
      categories: [],
      subCategories: [],
      products: [],
      productsVariants: [],
      variantData: [],
      editSalesOrder: null,
      onlyView: false,
      selectRule: [(v) => !!v || "Select any store"],
      inputRule: [(v) => !!v || "Field is required"],
      categoryId: [],
      subCategoryId: [],
      productId: [],
      productNames: [],
      variantId: [],
      invoice_no: [],
      // date: "",
      margin: [],
      net_price: [],
      retail_price: [],
      upc: [],
      sku: [],
      unit_cost: [],
      quantity: [],
      storeId: "",
      tax: "0",
      net_bottle_cost: [],
      total_amount: "0",
      vendor_name: "",
      vendor_address: "",
      mode_of_payment: "",
      purchaseImage: "",
      sendFiles: [],
      loading: false,
      showItemError: false,
      showErrorItem: null,
      menu: false,
      quantityError: false,
      purchaseNumber: [],
      arrayNumber: 1,
      deletePurchaseToggle: false,
      deletePurchaseIndex: "",
      imageUrl: process.env.VUE_APP_Image_Link + "Purchases/",
      allCategories: [],
      allSubCategories: [],
      allProducts: [],
      files: [],
      allSalesChannel: [],
      lowQuantity: [],
      imageDelete: false,
      deleteImageConfirm: "",
      date: new Date().toISOString().slice(0, 10),
      sales_channel: null,
    };
  },
  methods: {
    isPriceKey(event) {
      const reg = /^-?\d*(\.\d{0,2})?$/;
      let input = event.target.value + String.fromCharCode(event.charCode);

      if (!reg.test(input)) {
        event.preventDefault();
      }
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllCategories() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "category/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allCategories = response.data.categories;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoriesArray(storeId) {
      return this.allCategories.filter((el) => el.store_id == storeId);
    },
    getAllSubCategories() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "sub-category/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allSubCategories = response.data.subCategories;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubCategoriesArray(categoryId) {
      if (categoryId != undefined) {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + "product/getCategoryMargin/" + categoryId
          )
          .then((response) => {
            if (response.status == 200) {
              this.margin = response.data.categories.margin;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        return this.allSubCategories.filter(
          (subCategory) => subCategory.category_id == categoryId
        );
      }
    },
    getAllProducts() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allProducts = response.data.products;
            if (this.id != undefined) {
              this.getSalesData(this.id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProduct(productId) {
      return this.allProducts.filter((product) => product._id == productId);
    },
    getProductsArray(subCategoryId) {
      return this.allProducts.filter(
        (product) => product.sub_category_id == subCategoryId
      );
    },
    getProductsVariants(productId, index) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/edit/" + productId)
        .then((response) => {
          if (response.status == 200) {
            this.productsVariants = response.data.product.product_variants;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVariant(productId) {
      let variants = [];
      this.allProducts.filter((product) => {
        if (product._id == productId) {
          variants = product.product_variants;
        }
      });
      return variants;
    },
    getVariantData(variantId, productId, index) {
      this.getProductsVariants(productId, index);
      if (this.variantData[index] != null) {
        setTimeout(() => {
          this.productsVariants.forEach((variant) => {
            if (variant._id == variantId) {
              // this.variantData.push(variant);
              this.$set(this.variantData, index, variant);
            }
          });
        }, 1000);
      } else {
        setTimeout(() => {
          this.productsVariants.forEach((variant) => {
            if (variant._id == variantId) {
              this.variantData.push(variant);
            }
          });
        }, 1000);
      }
    },
    selectVariantFile() {
      this.files.forEach((file) => {
        this.sendFiles.push(file);
      });
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    deleteImage(image) {
      this.imageDelete = true;
      this.deleteImageConfirm = image;
    },
    closeDelete() {
      this.imageDelete = false;
      this.deleteImageConfirm = "";
    },
    deleteItemConfirm() {
      this.imageDelete = false;
      let data = {
        id: this.id,
        name: this.deleteImageConfirm,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "purchase/delete/image", data)
        .then((response) => {
          if (response.status == 200) {
            this.closeDelete();
            this.getSalesData(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSalesData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "sale/products/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.SalesChannel;
            this.sales_channel = data.sales_channel;
            this.date = data.date;
            this.storeId = data.store_id;
            // let totalPurchase = [];
            let categoriesId = [];
            let subCategoriesId = [];
            let quantity = [];
            let productsId = [];
            let variantsId = [];
            let variantDataEdit = [];
            let upc = [];
            let sku = [];
            response.data.SalesChannel.sales_data.forEach((sale, index) => {
              // totalPurchase.push(index);
              this.addMorePurchase();
              this.productDetails.push(this.getProduct(sale.product_id)[0]);
              categoriesId.push(sale.category_id);
              subCategoriesId.push(sale.subCategory_id);
              productsId.push(sale.product_id);
              variantsId.push(sale.variant_id);
              quantity.push(sale.quantity);
              upc.push(sale.upc);
              sku.push(sale.sku);
            });
            // this.purchaseNumber = totalPurchase;

            this.categoryId = categoriesId;
            this.subCategoryId = subCategoriesId;
            this.productId = productsId;
            this.variantId = variantsId;
            this.quantity = quantity;
            this.upc = upc;
            this.sku = sku;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMorePurchase() {
      if (this.editSalesOrder) {
        let last = this.purchaseNumber.length + 1;
        this.purchaseNumber.push(last);
      } else {
        this.purchaseNumber.push(this.arrayNumber);
        this.arrayNumber++;
      }
    },
    deletePurchase(index) {
      this.deletePurchaseToggle = true;
      this.deletePurchaseIndex = index;
    },
    deletePurchaseItem() {
      this.$delete(this.purchaseNumber, this.deletePurchaseIndex);
      this.$delete(this.categoryId, this.deletePurchaseIndex);
      this.$delete(this.productDetails, this.deletePurchaseIndex);
      this.$delete(this.productId, this.deletePurchaseIndex);
      this.$delete(this.variantId, this.deletePurchaseIndex);
      this.deletePurchaseToggle = false;
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let productPurchaseDetails = [];
        // let isAllRetailPriceAvailable = true;
        this.purchaseNumber.forEach((purchase, index) => {
          productPurchaseDetails.push({
            category_id: this.categoryId[index],
            subCategory_id: this.subCategoryId[index],
            product_id: this.productId[index],
            variant_id: this.variantId[index],
            quantity: this.quantity[index],
            upc: this.upc[index],
            sku: this.sku[index],
          });
        });

        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("store_id", this.storeId);
        formData.append("sales_channel", this.sales_channel);
        formData.append("date", this.date);
        formData.append("sales_data", JSON.stringify(productPurchaseDetails));

        if (this.onlyView) {
          this.$router.push({ name: "SalesOrder" });
        } else if (this.editSalesOrder == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "sale/products/update", formData)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "SalesOrder",
                  params: { edit: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.quantityError = true;
              if ((this.quantityError = true)) {
                window.scrollTo(0, 0);
              }
              if (error.response.data.message != null) {
                this.showItemError = true;
                this.showErrorItem = error.response.data.message;
                this.lowQuantity = error.response.data.message;
              }
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "sale/products/create", formData)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "SalesOrder",
                  params: { add: true },
                });
              }
            })
            .catch((error) => {
              // console.log(error.response.data.message)
              this.loading = false;
              this.quantityError = true;
              if ((this.quantityError = true)) {
                window.scrollTo(0, 0);
              }
              if (error.response.data.message != null) {
                this.showItemError = true;
                this.showErrorItem = error.response.data.message;
                this.lowQuantity = error.response.data.message;
              }
            });
        }
      }
    },
    getSalesChannelList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/sales_channel")
        .then((response) => {
          if (response.status == 200) {
            this.allSalesChannel = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findProduct() {
      let upcValue = "null";
      let skuValue = "null";
      this.searchValue = this.searchValue.trim();
      if (this.searchItem.toString() == "UPC") {
        upcValue = this.searchValue;
        console.log(upcValue);
      }
      if (this.searchItem.toString() == "SKU") {
        skuValue = this.searchValue;
        console.log(skuValue);
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "getProductFromUpc/" +
            upcValue +
            "/" +
            skuValue
        )
        .then((response) => {
          this.searchError = "";
          let product = response.data.data;
          if (product != null) {
            console.log("this.productDetails", this.productDetails);
            if (product.product_variants != null) {
              let prIndex = this.productDetails.findIndex(
                (pr) =>
                  pr.product_variants.variant_sku_number ==
                    product.product_variants.variant_sku_number ||
                  pr.product_variants.variant_upc_number ==
                    product.product_variants.variant_upc_number
              );
              console.log(prIndex);
              if (prIndex >= 0) {
                this.searchError =
                  "This " +
                  this.searchItem +
                  " Number " +
                  this.searchValue +
                  "is already added in current list.";
              } else {
                this.addMorePurchase();
                this.productDetails.push(product);
                this.categoryId.push(product.category_id);
                this.subCategoryId.push(product.sub_category_id);
                this.productId.push(product._id);
                this.variantId.push(product.product_variants._id);
                this.upc.push(product.product_variants.variant_upc_number);
                this.sku.push(product.product_variants.variant_sku_number);
              }
            } else {
              this.searchError =
                "No Product Found for the " +
                this.searchItem +
                " Number " +
                this.searchValue;
            }
          } else {
            this.searchError =
              "No Product Found for the " +
              this.searchItem +
              " Number " +
              this.searchValue;
          }

          setTimeout(() => {
            this.searchError = "";
          }, 5000);
        })
        .catch((error) => {
          this.loading = false;
          this.searchError =
            "No Product Found for the " + this.searchItem + " Number " + this.searchValue;

          setTimeout(() => {
            this.searchError = "";
          }, 5000);
        });
    },
  },
  mounted() {
    const route = this.$router.currentRoute;
    this.getSalesChannelList();
    this.getStoreList();
    this.getAllCategories();
    this.getAllSubCategories();
    this.getAllProducts();

    if (route.name == "View Sales") {
      this.onlyView = true;
    }
    if (route.name == "Edit Sales") {
      this.editSalesOrder = true;
    }
  },
};
</script>
<style scoped>
.parent-element {
  position: absolute;
  overflow: visible;
  height: 100%;
}

.sticky-child {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.vertical-middle {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.search {
  position: relative;
}

.search input {
  text-indent: 25px;
}

.search input:focus {
  box-shadow: none;
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px;
}

.search button {
  position: absolute;
  top: 3px;
  right: 5px;
  height: 40px;
  width: 100px;
  background: #cf9602 !important;
  border-color: #cf9602 !important;
  color: white;
  border-radius: 4px;
}
.float-right {
  color: #cf9602;
  /* padding-left: 100px; */
  font-size: smaller;
}
</style>
>
